<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <NuevoGasto @edit="getTableData()"></NuevoGasto>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
      >
        <template v-slot:item.fecha="{ item }">
          {{ new Date(item.fecha).toLocaleDateString() }}
        </template>

        <template v-slot:item.coste="{ item }"> {{ item.coste }} € </template>

        <template v-slot:item.acciones="{ item }">
          <EditarGasto :gasto="item" @edit="getTableData()"></EditarGasto>

          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoGasto from '../components/Gastos/NuevoGasto';
import EditarGasto from '../components/Gastos/EditarGasto';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        { text: 'Fecha', value: 'fecha' },
        { text: 'Categoría', value: 'categoria' },
        { text: 'Concepto', value: 'concepto' },
        { text: 'Coste', value: 'coste' },
        { text: 'Observaciones', value: 'observaciones' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/gastos');

        this.tableData = res.data;

        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;

        this.errorTableData = true;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(process.env.VUE_APP_API_URL + '/gastos/eliminar', {
          id,
        });
        this.getTableData();
      } catch (error) {
        alert('Error - No se ha podido eliminar');
      }
    },
  },
  components: {
    NuevoGasto,
    EditarGasto,
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>
