<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="mr-2" color="orange" v-bind="attrs" v-on="on">
        mdi-pencil
      </v-icon>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Editar gasto
      </v-card-title>
      <v-card-text>
        <v-menu
          ref="menu"
          v-model="fecha"
          :close-on-content-click="false"
          :return-value.sync="gasto.fecha"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="gasto.fecha"
              label="Fecha"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="gasto.fecha" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fecha = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="gasto.fecha = ''">
              Limpiar
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(gasto.fecha)">
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-select
          :items="categorias"
          label="Categoría"
          v-model="gasto.categoria"
        ></v-select>

        <v-text-field label="Concepto" v-model="gasto.concepto"></v-text-field>

        <v-text-field label="Coste" v-model="gasto.coste"></v-text-field>

        <v-text-field
          label="Observaciones"
          v-model="gasto.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editarGasto()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarGasto',
  props: ['gasto'],
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fecha: false,
      categorias: [
        'Hacienda',
        'Seguridad Social',
        'Nóminas',
        'Proveedores',
        'Servicios',
        'Material',
        'Otro',
      ],
    };
  },
  methods: {
    async editarGasto() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/gastos/editar', {
          id: this.gasto._id,
          fecha: this.gasto.fecha,
          categoria: this.gasto.categoria,
          concepto: this.gasto.concepto,
          coste: this.gasto.coste,
          observaciones: this.gasto.observaciones,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
